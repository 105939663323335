<template>
  <div class="app-container">
    <!-- 选项卡 -->
    <el-tabs type="border-card" v-model="activeName" @tab-click="changeUpdated">
      <el-tab-pane label="图书列表" name="first">
       
        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm" ref="queryForm" size="small" style="margin-bottom: 20px">
          <el-form-item prop="gender" v-if="false">
            <el-select v-model=queryForm.type style="width: 100px" @change="showChange">
              <el-option label="按图书搜" value="bookName" />
              <el-option label="按作者搜" value="author" />
            </el-select>
          </el-form-item>
          <el-form-item prop="username">
            <el-input v-model=queryForm.keyWord placeholder="输入图书名称或作者名称" clearable style="width:300px" />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" @click=querySubmit>查询</el-button>
          </el-form-item>
          <el-form-item style="position: absolute; right: 15px;">
            <el-button icon="el-icon-time" @click="openCondition">筛选</el-button>
            <el-button icon="el-icon-notebook-2" @click="excel.excelDialogVisible = true" >导出</el-button>
            <!-- <el-button icon="el-icon-notebook-2" @click="bookExcelImport"  @click="bookExcel">导入</el-button> -->
            <el-button @click="importDialog =true"> 导入图书</el-button>
            
          </el-form-item>
        </el-form>
        <!-- 导入图书示例 -->
        <div v-if="importDialog">
          <el-dialog append-to-body  :visible.sync="importDialog" title="导入图书" width="35%">
         <template>
          
          <ImportExcel :action="action"></ImportExcel>
          <!-- <a href="http://124.222.18.240:10147/down/Ujdrg5ywdpVT.xls">图书导入示例</a> -->
          <!-- <iframe src="https://view.officeapps.live.com/op/view.aspx?src=https://127.0.0.1:18080/ccc.xlsx&wdOrigin=BROWSELINK" width="100%" height="600px"></iframe> -->
          <a href="https://view.officeapps.live.com/op/view.aspx?src=https://admin.muxinsg.org.cn/图书导入示例.xls" target="_blank">点击下载模板</a>
         </template>
        </el-dialog>
        </div>
        <!-- 导出提示框 -->
        <div v-if="excel.excelDialogVisible">
          <el-dialog
            title="图书导出"
            :visible.sync="excel.excelDialogVisible"
            width="45%"
            :before-close="ExcelHandleClose"
            append-to-body>
            <template>
              <el-radio-group v-model="excel.excelRadio" size="mini" >
                <div >
                  <el-radio label="10"  v-if="multipleSelection.length>0">当前勾选的{{ multipleSelection.length }}条数据</el-radio>
                </div>
                <div >
                  <el-radio label="11" v-if="queryForm.keyWord != '' || bookSiftDTOList.length >0">当前筛选的 {{ this.page.total }}条数据</el-radio>
                </div>
                <div>
                  <el-radio label="22">导出全部的{{ this.excel.excelTotalNumber }}条数据</el-radio>
                </div>
              </el-radio-group>
             
            </template>

            <span slot="footer" class="dialog-footer">
              <el-button @click="excel.excelDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="excel.excelDialogVisible = false ,bookExcel()">确 定</el-button>
            </span>
          </el-dialog>
        </div>

        <!--  -->
        <!-- 点击添加筛选后增加的   -->
        <div class="middle" v-if="openStatus != false && bookSiftDTOList.length != 0"
          v-for="(item, index) in bookSiftDTOList">
          <div style="display: flex; align-items: center;margin-right: 5px;">且</div>
          <el-select class="middle-item" v-model="item.field" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

          <!-- 如果是总借阅次数 包含选择框 -->
          <el-select v-if="bookSiftDTOList[index].field == 13" class="middle-item" v-model="item.condition"
            placeholder="请选择" :disabled="bookSiftDTOList[index].field == ''">
            <el-option v-for="item in options3" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

          <el-select v-else class="middle-item" v-model="item.condition" placeholder="请选择"
            :disabled="bookSiftDTOList[index].field == ''">
            <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

          <!-- 如果是能否外借，包含 选择框 -->
          <el-select v-if="bookSiftDTOList[index].field == 4 && bookSiftDTOList[index].condition == 1" class="middle-item"
            v-model="item.content" placeholder="请选择" :disabled="bookSiftDTOList[index].field == ''">
            <el-option v-for="item in canBorrowOp" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

          <!-- 如果是图书位置，包含 选择框 -->
          <el-select v-if="bookSiftDTOList[index].field == 7 && bookSiftDTOList[index].condition == 1" class="middle-item"
            v-model="item.content" placeholder="请选择" :disabled="bookSiftDTOList[index].field == ''">
            <el-option v-for="item in optionsBookPosition" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

          <!-- 如果是下架时间，包含 日期 -->
          <el-date-picker v-if="bookSiftDTOList[index].field == 10 && bookSiftDTOList[index].condition == 1"
            v-model="item.content" type="date" placeholder="选择日期">
          </el-date-picker>

          <!-- 如果是所在分馆，包含 选择框 -->
          <el-select v-if="bookSiftDTOList[index].field == 12 && bookSiftDTOList[index].condition == 1"
            class="middle-item" v-model="item.content" placeholder="请选择" :disabled="bookSiftDTOList[index].field == ''">
            <el-option v-for="item in allSubMsg" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>

          <!-- 如果是总借阅次数  包含两个选择框 -->
          <el-input v-if="bookSiftDTOList[index].field == 13 && bookSiftDTOList[index].condition == 4" placeholder="请输入内容"
            v-model="item.content" clearable style="width: 30%;margin: 0 10px;">
          </el-input>
          <div v-if="bookSiftDTOList[index].field == 13 && bookSiftDTOList[index].condition == 4"
            style="display: flex;align-items: center;">~</div>
          <el-input v-if="bookSiftDTOList[index].field == 13 && bookSiftDTOList[index].condition == 4" placeholder="请输入内容"
            v-model="item.betweenContent" clearable style="width: 30%;margin: 0 10px;">
          </el-input>

          <!-- 如果是录入时间，包含 日期 -->
          <el-date-picker v-if="bookSiftDTOList[index].field == 14 && bookSiftDTOList[index].condition == 1"
            v-model="item.content" type="date" placeholder="选择日期">
          </el-date-picker>

          <!-- 每次添加一个选项 就要在这里 添加条件  -->
          <el-input
            v-if="bookSiftDTOList[index].field != '' && bookSiftDTOList[index].field != 4 && bookSiftDTOList[index].field != 7 && bookSiftDTOList[index].field != 10 && bookSiftDTOList[index].field != 12 && bookSiftDTOList[index].field != 13 && bookSiftDTOList[index].field != 14 && bookSiftDTOList[index].condition != ''"
            class="middle-item" v-model="item.content" placeholder="请输入内容"
            :disabled="bookSiftDTOList[index].condition == '' || bookSiftDTOList[index].condition == 2 || bookSiftDTOList[index].condition == 3"
            style="width: 40%;">
          </el-input>

          <el-input
            v-if="bookSiftDTOList[index].field == 13 && bookSiftDTOList[index].condition != '' && bookSiftDTOList[index].condition != 4"
            class="middle-item" v-model="item.content" placeholder="请输入内容"
            :disabled="bookSiftDTOList[index].condition == '' || bookSiftDTOList[index].condition == 2 || bookSiftDTOList[index].condition == 3"
            style="width: 40%;">
          </el-input>


          <div class="circle" @click="delCondition">
            _<div class="circle-item"></div>
          </div>
        </div>

        <div class="bottomBak" v-if="openStatus != false">
          <div class="txtBak" @click="addCondition">+添加筛选条件</div>
          <div class="right">
            <el-button size="small" style="height: 35px;" @click="clearCondition">清空条件</el-button>
            <el-button type="primary" size="small" style="height: 35px;" @click="startCondition22">开始筛选</el-button>
          </div>
        </div>

        <el-tabs style="margin-top: 15px;" v-model="TabactiveName" type="card" @tab-click="handleTabClick">
          <el-tab-pane label="馆藏" name="first"></el-tab-pane>
          <el-tab-pane label="在馆" name="second"></el-tab-pane>
          <el-tab-pane label="在借" name="third"></el-tab-pane>
          <el-tab-pane label="已下架" name="fourth"></el-tab-pane>
          <el-tab-pane label="全部" name="fifth"></el-tab-pane>
        </el-tabs>


        <!-- 表格数据 -->
        <el-table v-if="outIndex == '0'" ref="table" :data="tableData" @selection-change="handleSelectionChange"
          :header-cell-style="rowClass" >
          <el-table-column type="selection" width="55" align="center">
           
          </el-table-column>
          <!-- 图片封面scope.row.book.img -->
          <el-table-column  prop="book.img" label="封面" >
            <template slot-scope="scope">
              <!-- <img :src='scope.row.book.img' min-width="180" height="210"  /> -->
              <el-image
                style="width: 105px; height: 150px"
                :src="scope.row.book.img"
                fit="cover">
              </el-image>
            </template>
          </el-table-column>
          <!-- 跳转链接 -->
          <el-table-column  prop="book.bookName" label="书名" align="center">
            <template slot-scope="scope">
              <!-- <el-link href="/admin/bookdetail?id=123" type="primary">{{ scope.row.bookName }}</el-link> -->
              <router-link :to="{ path: '/admin/bookdetail', query: { letuCode: scope.row.book.letuCode } }" type="primary">{{
                scope.row.book.bookName }}</router-link>
            </template>
          </el-table-column>
          <el-table-column prop="book.letuCode" label="沐心码" align="center" />
          <el-table-column prop="book.author" label="作者" align="center" />
          <el-table-column prop="book.borrowTimes" label="借阅次数" align="center" />
          <el-table-column prop="book.bookPosition" label="位置" align="center" >
            <template slot-scope="scope" >
              <div style="display: flex;">
                <div v-if="scope.row.book.bookPosition != null" >{{scope.row.book.bookPosition.substring(0,3)}}</div>
              <div v-if="scope.row.book.bookPosition != null" :style=" {backgroundColor: scope.row.book.bookPosition.substring(4,11), width: '20px', height: '20px', marginLeft: '5px' }"> </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="branchName" label="所在分馆" align="center" />
          <el-table-column prop="book.status" label="状态" align="center">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.book.status == 0" type="success">在馆</el-tag>
              <el-tag v-else type="warning">已借出</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100px" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" icon="el-icon-edit" type="p rimary" plain
                @click="handleEdit(scope.row)">修改</el-button>
              <el-button size="mini" icon="el-icon-star-off" type="warning" plain
                @click="handleDelete(scope.row)">{{scope.row.book.isRemove == 0 ? "下架" : "上架"}}</el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-pagination v-if="outIndex == '0'" background @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page="page.current" :page-size="page.size" :page-count="page.pageTemp"
          :page-sizes="[2, 5, 10, 20, 100]" layout="total, sizes, prev, pager, next, jumper" :total="page.total">
        </el-pagination>
      </el-tab-pane>


      <el-tab-pane label="图书录入" name="second" >
        <el-form ref="form" :model="regBookForm" :rules="regBookFormRules" label-width="150px">
          <div style="display: flex;justify-content: space-between; position: relative;">
            <div style="display: flex;flex-direction: column;">
              <el-form-item label="ISBN码：" prop="isbn" >
                <el-input v-model="regBookForm.isbn" placeholder="扫描/输入 图书ISBN码" @change="sendIsbn"></el-input>
              </el-form-item>
              <el-form-item label="沐心码：" prop="letuCode">
                <!-- <el-input v-model="regBookForm.letuCode" placeholder="扫描/输入 沐心码" @change="trimInput"></el-input> -->
                <el-input v-model="regBookForm.letuCode" placeholder="扫描/输入 沐心码" ></el-input>
              </el-form-item>
            </div>
            <div style="position: absolute; right: 60px;bottom: 0px;">
              <el-form-item>
                <el-button type="success" @click="onSubmit('form')" size="small">确 定 录 入</el-button>
                <el-button size="small" @click="clearForm('form')">清 空</el-button>
                <el-button size="small" @click="goLibraryList">返回图书列表</el-button>
              </el-form-item>
            </div>
          </div>
          <el-divider></el-divider>
          <div style="display:flex;padding: 10px;">
            <!-- 封面 -->
            <div style="display: flex;flex-direction: column;">
              <div style="display: flex;">
                <div style="width: 55px;margin-right: 10px;">封面: </div>
                <el-upload class="avatar-uploader" action="https://api.leshop.cloud/book/uploadImage"
                   name="image"
                   :headers="headers"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess" 
                  :before-upload="beforeAvatarUpload">
                  <img v-if="imageUrl" :src="imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  <div style="margin-top: -50px;font-size: 14px;color: #858585;">拖拽 / 点击上传封面</div>
                  <div style="margin-top: 80px;"></div>
                </el-upload>
              </div>
              <div style="color: #2d8cf0;font-size: 14px;width: 160px;margin-left: 75px;">上传图书封面，帮助我们完善图书信息！</div>
            </div>
            <div>
              <div style="display: grid;grid-template-columns: 1fr 1fr;">
                <div>
                  <el-form-item label="书名：" prop="bookName">
                    <el-input v-model="regBookForm.bookName" placeholder="请输入书名"></el-input>
                  </el-form-item>
                  <el-form-item label="副标题：">
                    <el-input v-model="regBookForm.subtitle" placeholder="请输入副标题"></el-input>
                  </el-form-item>
                  <el-form-item label="系列情况：" prop="seriesSituation">
                    <el-radio v-model="regBookForm.seriesSituation" label="0" @input="openSeries">单本书</el-radio>
                    <el-radio v-model="regBookForm.seriesSituation" label="1" @input="openSeries">丛书</el-radio>
                    <el-input v-model="regBookForm.series" placeholder="丛书名字" v-if="openSeriesCode"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="作者：" prop="author">
                    <el-input v-model="regBookForm.author" placeholder="请输入作者"></el-input>
                  </el-form-item>
                  <el-form-item label="译者：">
                    <el-input v-model="regBookForm.interpreter" placeholder="请输入译者"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div style="display: grid;grid-template-columns: 1fr 1fr;">
                <div>
                  <el-form-item label="中国图法分类：">
                    <el-input v-model="regBookForm.clc" placeholder="文学/中国文学/散文"></el-input>
                  </el-form-item>
                  <el-form-item label="中国图法分类号：">
                    <el-input v-model="regBookForm.clcNumber" placeholder="如J238.2，可查看图书编号"></el-input>
                  </el-form-item>
                  <!-- prop="publishDate" -->
                  <el-form-item label="出版时间：" >
                    <!-- <el-date-picker v-model="regBookForm.time" type="month" placeholder="请选择出版时间"></el-date-picker> -->
                    <el-date-picker
                      v-model="regBookForm.publishDate"
                      type="date"
                      placeholder="选择日期"
                      format="yyyy 年 MM 月 dd 日"
                      value-format="yyyy-MM-dd">
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="价格：">
                    <el-input v-model="regBookForm.price" placeholder="0"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="出版社：">
                    <el-input v-model="regBookForm.publisher" placeholder="请输入出版社"></el-input>
                  </el-form-item>
                  <el-form-item label="页数：">
                    <el-input v-model="regBookForm.pages" placeholder="0"></el-input>
                  </el-form-item>
                </div>
              </div>
              <el-divider></el-divider>
              <div style="display: grid;grid-template-columns: 1fr 1fr;">
                <div>
                  <!-- prop="customizeNumber" -->
                  <el-form-item label="自定索书号：" >
                    <el-input v-model="regBookForm.customizeNumber"></el-input>
                  </el-form-item>
                  <el-form-item label="来源(捐赠人ID)：" prop="donorsId" v-if="false">
                    <el-input v-model="regBookForm.donorsId"></el-input>
                  </el-form-item>
                  <!-- prop="donorsName" -->
                  <el-form-item label="来源(捐赠人姓名)：" >
                    <el-input v-model="regBookForm.donorsName"></el-input>
                  </el-form-item>
                  <el-form-item label="能否外借：" prop="canBorrow">
                    <el-radio v-model="regBookForm.canBorrow" label="0">可外借</el-radio>
                    <el-radio v-model="regBookForm.canBorrow" label="1">不可外借</el-radio>
                  </el-form-item>
                </div>
                <div>
                  <!-- prop="bookPosition" -->
                  <el-form-item label="图书位置：" >
                    <div style="display: flex;">
                      <el-select v-model="bookPositionSelect.bookPosition1" placeholder="请选择">
                        <el-option v-for="item in optionsBook" :key="item.value" :label="item.label" :value="item.value"
                          :disabled="item.disabled">
                        </el-option>
                      </el-select>
                      <el-select v-model="bookPositionSelect.bookPosition2" placeholder="请选择" style="margin-left: 5px;">
                        <el-option v-for="item in options1Book" :key="item.value" :label="item.label" :value="item.value"
                          :disabled="item.disabled">
                        </el-option>
                      </el-select>
                      <el-color-picker v-model="bookPositionSelect.color2" style="margin-left: 5px;"></el-color-picker>
                      <div style="color: #2d8cf0;cursor: pointer; align-items: center;margin:-20px 0 0 5px;" @click="clearbookPosition">清空
                      </div>
                    </div>
                  </el-form-item>
                </div>
              </div>
              <el-form-item label="备注：">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="regBookForm.remark"
                  :autosize="{ minRows: 2, maxRows: 4 }">
                </el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <!-- 编辑或添加对话框 -->
    <el-dialog :title="dialogTitle" :modal="false" :visible.sync="dialogFormVisible" width="42%" append-to-body>
      <el-form ref="dialogForm" :model="dialogForm" label-width="80px" inline>
        <el-form-item label="图书编号" prop="isbn">
          <el-input v-model="dialogForm.isbn" maxlength="13" autocomplete="off" />
        </el-form-item>
        <el-form-item label="图书名称" prop="bookName">
          <el-input v-model="dialogForm.bookName" autocomplete="off" />
        </el-form-item>
        <el-form-item label="作者" prop="author">
          <el-input v-model="dialogForm.author" autocomplete="off" />
        </el-form-item>
        <el-form-item label="出版社" prop="publisher">
          <el-input v-model="dialogForm.publisher" autocomplete="off" />
        </el-form-item>
        <el-form-item class="intro-item" label="简介" prop="intro">
          <el-input type="textarea" v-model="dialogForm.intro" autocomplete="off" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog">取 消</el-button>
        <el-button type="primary" @click="submitDialog">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getToken} from '@/utils/auth.js'
import { listBook, addBook, updateBook, removeBook, getISBN, exportBook, uploadImage } from '@/api/book'
import ImportExcel from '../../../components/ImportExcel.vue'
import  {debounce }  from 'lodash';
export default {
  components:{
    ImportExcel
  },
 
  data() {
    var validateBookPosition = (rule, value, callback) => {
      
      if (this.bookPositionSelect.bookPosition1 =='' && this.bookPositionSelect.bookPosition2 === '' && this.bookPositionSelect.color2 === '') {
        callback(new Error('请输入书籍位置'));
      } else {
        callback();
      }
    };
    return {
      // isProcessing: false,
      // timeoutId: null,
      importDialog : false,
      shouldUpdate: true, // 示例标志，控制是否执行特定操作
      //控制导出弹框显示的
      excel:{
        excelDialogVisible: false,
        excelRadio: '',
        excelTotalNumber: undefined,
        startConditionSelect: []
      },
      //当前上传图片的url地址
       action:"https://api.leshop.cloud/book/import",
      isImport:false,
      headers:{
        "Authorization": getToken() //"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyb2xlIjoiYWRtaW4iLCJiaWQiOjEsImFpZCI6MSwiYWNjb3VudCI6ImxldHUxMjM0IiwidXNlcm5hbWUiOiLnqIvmoLnno4ogICJ9.AkDHUs_6NXASjfwNqc1G9pB-eCO5ZFiSoSQK_5WBLeU"

      },
      // 丛书显示
      openSeriesCode:false,
      //选中的图书的id 
      bookIdList:[],
      // 是否下架
      isGrounding: "下架",
      //馆藏类型  默认0馆藏    1 在馆  2 再借  3 已下架   4 全部
      bookType: 0,
      queryForm: {
        type: "",
        keyWord: ""
      },
      // 按钮禁用
      editDisabled: true,
      removeDisabled: true,
      // 表格数据
      tableData: [],
      // 多选数据
      multipleSelection: [],
      // 分页数据
      page: {
        // current: 1, // 当前页
        // size: 10, // 每页显示数
        // total: undefined // 总计
        current: 1,
        pageTemp: 1,
        size: 10,
        total: undefined
      },
      // 对话框类型[add|edit]
      dialogType: '',
      // 对话框是否可见
      dialogFormVisible: false,
      // 对话框标题
      dialogTitle: undefined,
      // 对话框数据
      dialogForm: {},

      imageUrl: '',
      // =========录入图书===========
      //图书位置 
      bookPositionSelect:{
          bookPosition1: "", //图书位置
          bookPosition2: "",
          color2:""
        },
      regBookForm: {
        isbn: "", //图书isbn
        img: '', // 图片url 地址
        subtitle: "", // 副标题
        letuCode: "", // letu码
        bookName: "", //图书名字
        clc:"", //图法分类
        clcNumber: "", // 中国图法分类号
        publishDate: "", //出版时间
        publisher: "", //出版社
        author: "", //作者
        interpreter:"", // 译者
        pages: "", //页数
        customizeNumber:"", //自定义索书号
        donorsId:"", //捐赠人id
        donorsName: "", //捐赠人姓名
        bookPosition:"",
        applicationName: "",  //第三方应用名
        seriesSituation: "0",  // 是否是 丛书 
        canBorrow: "0", //是否外借 
        remark: "" ,//备注
        price:"",//价格
        series:"" ,//丛书名字
        authorNumber:""// 著作号
      },
      regBookFormBak: {
        isbn: "",
        img: '',
        subtitle: "",
        letuCode: "",
        bookName: "",
        clcNumber: "",
        time: "",
        publisher: "",
        author: "",
        pages: "",
        donorsName: "",
        bookPosition1: "",
        bookPosition2: "",
        applicationName: "",
        seriesSituation: "",
        canBorrow: "",
        remark: ""
      },
      // 必填规则
      regBookFormRules: {
        bookName: [{
          required: true, message: '请输入书名', trigger: 'blur'
        }],
        // donorsName: [{
        //   required: true, message: '请填写图书来源 / 捐赠人姓名', trigger: 'blur'
        // }],
        isbn: [{
          required: true, message: '请输入ISBN码', trigger: 'blur'
        }],
        letuCode: [{
          required: true, message: '请输入沐心码', trigger: 'blur'
        }],
        seriesSituation: [{
          required: true, message: '请选择系列情况', trigger: 'change'
        }],
        canBorrow: [{required: true, message: '请选择是否可外借', trigger: 'change'}],
        author: [{required: true, message: '请输入作者名称', trigger: 'change'}],
       // publishDate: [{required: true, message: '请输入出版时间', trigger: 'change'}],
        // customizeNumber: [{required: true, message: '请输入自定义编号', trigger: 'blur'}],
        // bookPosition: [{required: true, validator: validateBookPosition, message: '请输勾选籍位置', trigger: 'blur'}]
      },
      optionsBook: [
      { value: 'A', label: 'A' },
      { value: 'B', label: 'B' },
      { value: 'C', label: 'C' },
      { value: 'D', label: 'D' },
      { value: 'E', label: 'E' },
      { value: 'F', label: 'F' },
      { value: 'G', label: 'G' },
      { value: 'H', label: 'H' },
      { value: 'I', label: 'I' },
      { value: 'J', label: 'J' },
      { value: 'K', label: 'K' },
      { value: 'L', label: 'L' },
      { value: 'M', label: 'M' },
      { value: 'N', label: 'N' },
      { value: 'O', label: 'O' },
      { value: 'P', label: 'P' },
      { value: 'Q', label: 'Q' },
      { value: 'R', label: 'R' },
      { value: 'S', label: 'S' },
      { value: 'T', label: 'T' },
      { value: 'U', label: 'U' },
      { value: 'V', label: 'V' },
      { value: 'W', label: 'W' },
      { value: 'X', label: 'X' },
      { value: 'Y', label: 'Y' },
      { value: 'Z', label: 'Z' }      ],
      options1Book: [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
      { value: 6, label: '6' },
      { value: 7, label: '7' },
      { value: 8, label: '8' },
      { value: 9, label: '9' },
      { value: 10, label: '10' },
      { value: 11, label: '11' },
      { value: 12, label: '12' },
      { value: 13, label: '13' },
      { value: 14, label: '14' },
      { value: 15, label: '15' },
      { value: 16, label: '16' },
      { value: 17, label: '17' },
      { value: 18, label: '18' },
      { value: 19, label: '19' },
      { value: 20, label: '20' },
      { value: 21, label: '21' },
      { value: 22, label: '22' },
      { value: 23, label: '23' },
      { value: 24, label: '24' },
      { value: 25, label: '25' },
      { value: 26, label: '26' }     ],
      // color2: null,



      activeName: "first",
      TabactiveName: "first",

      // ==============图书列表=========
      TabOutName: "",

      // =================筛选=============
      openStatus: false,
      // 筛选的条件
      conditionList: [{
        field: "",
        condition: "",
        content: "",
        // 当选择总借阅次数的时候才会启用！
        betweenContent: "",
      }],
      bookSiftDTOList: [],
      options: [{
        label: 'ISBN',
        value: 1,
        disabled: false
      }, {
        label: '书名',
        value: 2,
        disabled: false
      }, {
        label: '作者',
        value: 3,
        disabled: false
      }, {
        label: '能否外借',
        value: 4,
        disabled: false
      }, {
        label: '自定义索书号',
        value: 5,
        disabled: false
      }, {
        label: '图书来源',
        value: 6,
        disabled: false
      }, {
        label: '图书位置',
        value: 7,
        disabled: false
      }, {
        label: '丛书名',
        value: 8,
        disabled: false
      }, {
        label: '下架原因',
        value: 9,
        disabled: false
      }, {
        label: '下架时间',
        value: 10,
        disabled: false
      }, {
        label: '备注',
        value: 11,
        disabled: false
      }, {
        label: '所在分馆',
        value: 12,
        disabled: false
      }, {
        label: '总借阅次数',
        value: 13,
        disabled: false
      }, {
        label: '录入时间',
        value: 14,
        disabled: false
      }],
      options1: [{
        label: '包含',
        value: 1
      }, {
        label: '为空',
        value: 2
      }, {
        label: '不为空',
        value: 3
      }],
      options2: [{
        value: 'reader',
        label: '按读者搜'
      }, {
        value: 'book',
        label: '按图书搜'
      }],
      options3: [{
        value: 1,
        label: "等于"
      }, {
        value: 2,
        label: "大于等于"
      }, {
        value: 3,
        label: "小于等于"
      }, {
        value: 4,
        label: "介于"
      }],
      // 图书位置选项
      optionsBookPosition: [],
      // 所有分馆信息选项
      allSubMsg: [],
      // 能否外借选项
      canBorrowOp: [{
        value: 0,
        label: "可外借"
      }, {
        value: 1,
        label: "不可外借"
      }],

      // 最外层筛选 图表列表 标签管理
      outIndex: "0",
    }
  },
  created() {
    this.getList();
   
  },
  watch:{
    // 'regBookForm.letuCode'(newVal){
    //   const url = newVal;
    //   if (url.startsWith('idivip.com/book/')) {
    //     this.regBookForm.letuCode = url.replace('idivip.com/book/', '');
    //   } else if(url.lastIndexOf('/') != -1){
    //     // Handle invalid input or show an error message
    //     //console.error('Invalid input format');
    //     const index = url.lastIndexOf('/')
    //     this.regBookForm.letuCode = url.slice(index+1);
    //   }else{
    //     this.regBookForm.letuCode = url;
    //   }
    //   //console.log(this.regBookForm.letuCode)
    // },
    // 调用第三方库 
  //   'regBookForm.letuCode': {
  //    handler: debounce(function(newVal){
  //     // console.log(newVal)
  //     const url = newVal;
  //     if (url.startsWith('idivip.com/book/')) {
  //       this.regBookForm.letuCode = url.replace('idivip.com/book/', '');
  //     } else if(url.lastIndexOf('/') != -1){
  //       // Handle invalid input or show an error message
  //       //console.error('Invalid input format');
  //       const index = url.lastIndexOf('/')
  //       this.regBookForm.letuCode = url.slice(index+1);
  //     }else{
  //       this.regBookForm.letuCode = url;
  //     }
  //    }, 1500)
  // }
    // 使用 setTimeout 延迟执行函数
    // 'regBookForm.letuCode'(newVal){
    //   //console.log(newVal)
    //   if (this.isProcessing) return; // 如果已有定时器在运行，跳过此次处理
    //   this.isProcessing = true;
    //   console.log(newVal)
    //   const url = newVal;
    //   const processNewValue = () => {
            
    //         if (url.startsWith('idivip.com/book/')) {
    //           console.log('第一个')
    //           console.log(url.replace('idivip.com/book/', ''))
    //           this.regBookForm.letuCode = url.replace('idivip.com/book/', '');
    //           console.log(url)
    //           console.log(this.regBookForm.letuCode)
    //         } else if(url.lastIndexOf('/') != -1){
    //           console.log('第二个')
    //           const index = url.lastIndexOf('/')
    //           this.regBookForm.letuCode = url.slice(index+1);
    //         }
    //           console.log('第三个')
    //          // this.regBookForm.letuCode = url;
            
    //         console.log(this.regBookForm.letuCode)
    //         this.isProcessing = false;
    //   }
    //   //clearTimeout(this.timeoutId); // 清除可能存在的旧定时器
    //   this.timeoutId = setTimeout(processNewValue, 4000); // 设置新的定时器
    // }
  },
  updated(){
    
    //console.log("我执行了")
    //console.log(this.$refs['form'])
      if(this.shouldUpdate){
       // console.log("我执行了"+this.shouldUpdate)
        this.getTotalNum()
      }
    // console.log(this.$refs['form'].$el)
    // if(this.$refs['form'].nodeName != 'FORM'){
    //   console.log("我执行了")
      
    // }else{
    //   console.log("我执行了2")
    //   this.getTotalNum()
    // }
   // console.log(this.excel.startConditionSelect)

  },
  methods: {
    // 返回图书列表
    goLibraryList(){
      this.activeName = 'first'
    },
    //去除 前缀
    trimInput() {
      const url = this.regBookForm.letuCode;
      if (url.startsWith('idivip.com/book/')) {
        this.regBookForm.letuCode = url.replace('idivip.com/book/', '');
      } else if(url.lastIndexOf('/') != -1){
        // Handle invalid input or show an error message
        //console.error('Invalid input format');
        const index = url.lastIndexOf('/')
        this.regBookForm.letuCode = url.slice(index+1);
      }else{
        this.regBookForm.letuCode = url;
      }
      //console.log(this.regBookForm.letuCode)
    },

    changeUpdated(tab){
      if(tab.name == 'first'){
        //console.log('我是first')
        this.shouldUpdate = true
      }else{
        //console.log('woshi')
        this.shouldUpdate = false
      }
      
    },
  // excel表格数据 导出
  // excelSelect(label){
  //     if(label == 0){

  //     }else if(label == 1){

  //     }else{

  //     }
  // },
  //打开丛书按钮
  openSeries(label){
    if(label == 1){
      this.openSeriesCode=true
    }else{
      this.openSeriesCode=false
    }
  },

  // 清空图书位置
  clearbookPosition(){
    this.bookPositionSelect.bookPosition1=""
    this.bookPositionSelect.bookPosition2=""
    this.bookPositionSelect.color2=""

  },
  //发送isbn
  sendIsbn(){
    
    getISBN(this.regBookForm.isbn).then((data)=>{
         //this.regBookForm =data.data
         if(data.data.code == '200'  || data.code =="200"){
         this.regBookForm.publishDate = data.data.publishDate
         this.regBookForm.bookName = data.data.bookName
         this.regBookForm.letuCode = data.data.letuCode
         this.regBookForm.isbn = data.data.isbn
         this.regBookForm.author = data.data.author
         this.regBookForm.subtitle = data.data.subtitle
         this.regBookForm.interpreter = data.data.interpreter
         this.regBookForm.seriesSituation = data.data.seriesSituation == '' ? '0' : data.data.seriesSituation
         this.regBookForm.series = data.data.series
         this.regBookForm.clc = data.data.clc
         this.regBookForm.clcNumber = data.data.clcNumber
         this.regBookForm.authorNumber = data.data.authorNumber
         this.regBookForm.publisher = data.data.publisher
         this.regBookForm.pages = data.data.pages
         this.regBookForm.customizeNumber = data.data.customizeNumber
         //this.regBookForm.bookPosition = data.data.bookPosition
         this.regBookForm.donorsName = data.data.donorsName
        this.regBookForm.img=data.data.img

         this.imageUrl = data.data.img
         }else{
          // data.data.message = "请输入正确的ISBN"
           this.$message.error(data.data.message)
         }
      //console.log(data.data)

    })
  },
    // dao 
   //添加筛选按钮
   startCondition22(){
    this.startCondition(this.bookType)
   },
    // upload 上传图片
    uploadImg(){
      //console.log("我被带你记录")
      uploadImage().then((res)=>{
       this.imageUrl = res.data
      })
    },
    handleAvatarSuccess(res, file) {
        this.imageUrl = URL.createObjectURL(file.raw);
        console.log(res)
        res != null ? this.regBookForm.img = res.data : this.regBookForm.img ='';
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' || 'image/jpg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },

    // ==================筛选=================
    // 开启筛选条件
    openCondition() {
      this.openStatus = !this.openStatus;
    },
    // 添加条件
    addCondition() {
      this.bookSiftDTOList.push({
        field: "",
        condition: "",
        content: ""
      })
       //console.log("增加一个", this.bookSiftDTOList.length);
    },
    // 删除条件
    delCondition() {
      this.bookSiftDTOList.pop()
      console.log("删除一个", this.bookSiftDTOList.length, this.bookSiftDTOList);
    },
    // 清空条件
    clearCondition() {
      this.conditionList = [{
        field: "",
        condition: "",
        content: ""
      }];
      this.bookSiftDTOList = [];
    },
    // 开始筛选  多条件筛选！！！
    startCondition(type) {
      console.log()
      let data = { bookSiftDTOList: this.bookSiftDTOList, keyWord: this.queryForm.keyWord, type:type }
      console.log(data);
      listBook(data).then(({ data }) => {
        this.tableData = data.bookDataList;
        this.excel.startConditionSelect = []
        data.bookDataList.forEach((item)=>{
          //console.log(item.book.id)
          this.excel.startConditionSelect.push(item.book.id)
        })
        
        this.page.total = data.total
        this.page.current = data.current 
        this.page.pageTemp = data.pageNum;
                
      })
    },

    // 获得所有图书位置
    getBookPosition() {
      BookPosition().then(({ data }) => {
        for (let i = 0; i < data.length; i++) {
          this.optionsBookPosition.push({ value: i, label: data[i] })
        }
      })
      // console.log(this.optionsBookPosition);
    },
    // 获得所有分馆信息
    getAllSubMsg() {
      listLibrary().then(({ data }) => {
        for (let i = 0; i < data.libraryBranchData.length; i++) {
          this.allSubMsg.push({ value: i, label: data.libraryBranchData[i].branchName })
        }
      })
      // console.log(this.allSubMsg);
    },
    showChange(data) {
      this.queryForm.type = data
      console.log(data);
    },


    // 底下筛选条件 使得只要是图书列表界面 馆藏 在馆~~都显示
    handleClick(tab, event) { this.outIndex = tab.index;close.log(tab.index) },
    // 处理馆藏 在馆 更改数据
    handleTabClick(tab) {
      this.TabOutName = tab.name;
      let type = 0;
      if (this.TabOutName == 'first') { type = 0;this.startCondition(type);this.bookType=0 ;this.isGrounding="下架"}
      if (this.TabOutName == 'second') { type = 1; this.startCondition(type);this.bookType=1 ;this.isGrounding="下架"}
      if (this.TabOutName == 'third') { type = 2; this.startCondition(type) ;this.bookType=2;this.isGrounding="下架"}
      if (this.TabOutName == 'fourth') { type = 3; this.startCondition(type) ;this.bookType=3;this.isGrounding="上架"}
      if (this.TabOutName == 'fifth') { type = 4; this.startCondition(type) ;this.bookType=4;this.isGrounding="下架"}
    },
    // 抽离出来
    handleTabData(type = 0) {
      let queryParams = { type }
      listBook(queryParams).then(({ data }) => {
        this.tableData = data.bookDataList;
        this.page.total = data.total
        this.page.current = data.current 
        this.page.pageTemp = data.pageNum;
      })
    },
    // 表格居中
    rowClass() {
      return "text-align:center; background: #87CEEB; ";
    },



    // ================图书录入===================
    onSubmit(form) {
      this.regBookForm.bookPosition=this.bookPositionSelect.bookPosition1+"-"+this.bookPositionSelect.bookPosition2+"-"+this.bookPositionSelect.color2;
      
      const queryParams = { ...this.regBookForm };
      // console.log(queryParams);
      this.$refs[form].validate((valid,value)=>{
          if(valid){
            addBook(queryParams).then(res => {
              console.log(res);
              if (res.code == '200') {
                alert("添加成功")
              }else{
                alert(res.data.message)
              }
            })
          }else{
            //console.log("校验失败")
            Object.values(value).forEach((item)=>{setTimeout(()=>{this.$message.error( item[0].message)},500)}) 
          }
        }) 
      
    },
    clearForm(form) {
      this.regBookForm = {};
      this.clearbookPosition();
      this.imageUrl=""
      this.$refs[form].resetFields()

    },


    //============== 查询表单 =================
    // 查询表单提交
    querySubmit() {
      //this.openStatus = false;
      console.log(this.queryForm.keyWord)
      let queryParams1 = {keyWord:this.queryForm.keyWord, page: this.page.current, size: this.page.size, type:this.bookType}
      console.log(queryParams1)

      listBook(queryParams1).then(({ data }) => {
        this.excel.startConditionSelect = []
        data.bookDataList.forEach((item)=>{
          //console.log(item.book.id)
          this.excel.startConditionSelect.push(item.book.id)
        })
        this.tableData = data.bookDataList
        this.page.total = data.total
        this.page.current = data.current 
        this.page.pageTemp = data.pageNum;
      })
    },



    //============== 图书API =============
    // 查询图书列表
    getList() {
      console.log(this.queryForm);
      const queryParams = { page: this.page.current, size: this.page.size, type:0 }
      listBook(queryParams).then(({ data }) => {
        this.excel.startConditionSelect = []
        data.bookDataList.forEach((item)=>{
          //console.log(item.book.id)
          this.excel.startConditionSelect.push(item.book.id)
        })
        this.tableData = data.bookDataList
        //console.log(this.tableData)
        this.page.total = data.total
        this.page.current = data.current 
        this.page.pageTemp = data.pageNum;
      })
    },
    //获取当前的全部数据
    getTotalNum(){
      const queryParams = { page: this.page.current, size: this.page.size, type:this.bookType }
      listBook(queryParams).then(({ data }) => {
       this.excel.excelTotalNumber = data.total
      })
    },
    // 添加图书
    addData() {
      addBook(this.dialogForm).then(() => {
        this.msgSuccess('添加成功!')
        this.getList()
      })
    },
    // 修改图书
    updateData() {
      if(this.dialogForm.seriesSituation == null || this.dialogForm.seriesSituation == ''){
        this.dialogForm.seriesSituation = 0
      
      } 
      updateBook(this.dialogForm).then((data) => {
        //console.log(data)
        if(data.code == 200){
          this.msgSuccess('修改成功!')
        
        }else{
          this.msgError(data.data.message)
        }
        //this.msgSuccess('修改成功!')
        this.getList()
      })
    },
    // 删除图书
    deleteData(ids,type) {
      removeBook(ids,type).then((data) => {
        if(data.code == 200)
        this.msgSuccess('修改成功!')
        let queryParams1 = {keyWord:this.queryForm.keyWord, page: this.page.current, size: this.page.size, type:this.bookType}
          // console.log("我执行了")

          listBook(queryParams1)
          .then(({ data }) => {
            //console.log(data)
            this.tableData = data.bookDataList
            this.page.total = data.total
            this.page.current = data.current 
            this.page.pageTemp = data.pageNum;
          })

      })
      //this.startCondition(this.bookType)
    },

    //============== 查询表单 =================
    // 查询表单提交
    // querySubmit() {
    //   console.log(this.queryForm)
    //   this.getList()
    // },

    //============== 表格 ==================
    //  表格 勾选触发事件
    handCheckbox (val){
      // console.log(selection)
      // console.log(row)
       const res= val.isbn
       this.bookIdList.push({isbn:res})
      console.log(this.bookIdList)
    },
    // 多选改变
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.editDisabled = !(val.length === 1)
      this.removeDisabled = !(val.length > 0)
    },
    // 删除行数据
    handleDelete(row) {
      let id = row.target ? this.multipleSelection.map(item => item.book.id) : row.book.id
      let type = row.target ? this.multipleSelection.map(item => item.book.isRemove) : row.book.isRemove
      let status = row.target ? this.multipleSelection.map(item => item.book.status) : row.book.status
      let bookName = row.target ? this.multipleSelection.map(item => item.book.bookName) : row.book.bookName

      console.log(id+"======"+type)
      if(type == 0){
        this.$confirm('是否下架该图书?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
        })
        .then(() => {
          if(status == 0){
            let ids = [] 
          ids.push(JSON.parse(id))
          let type = 1 
          this.deleteData(ids,type)
          }else{
            this.$message(bookName+"已被借阅 ，不允许下架")
          }
          
        
        })
        .catch(() => { })
      }else{
        this.$confirm('是否上架该图书?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
        })
        .then(() => {
          let ids = [] 
          ids.push(JSON.parse(id))
          let type = 0 
          
          this.deleteData(ids,type)
          


      
        })
        .catch(() => { 
         // console.log("哈哈  小爷我被取消了")

        })
      }
    },
    // 添加数据
    handleAdd() {
      // 清除对话框表单数据
      this.dialogForm = {}
      this.dialogType = 'add'
      this.dialogTitle = '添加图书'
      this.showDialog()
    },
    // 编辑数据
    handleEdit(val) {
      console.log(val)
      this.dialogForm = val.book
      this.dialogType = 'edit'
      this.dialogTitle = '修改图书'
      this.showDialog()
    },

    //============== 添加或编辑对话框 ===================
    // 显示对话框
    showDialog() {
      this.dialogFormVisible = true
    },
    // 对话框取消
    cancelDialog() {
      this.dialogFormVisible = false
    },
    // 对话框提交
    submitDialog() {
      //console.log(this.dialogForm)
      if (this.dialogType == 'add') {
        // console.log('执行添加表单')
        this.addData()
      } else {
        // console.log('执行修改表单')
        this.updateData()
      }
      this.dialogFormVisible = false
    },

    //=============== 分页 ==================
    // 分页发生改变函数
    hangBookPageChange() {
      let params = {bookSiftDTOList:this.bookSiftDTOList , keyWord: this.queryForm.keyWord, type: this.bookType,page: this.page.current, size: this.page.size}
      listBook(params).then(({ data }) => {
        console.log(data)
        this.tableData = data.bookDataList
        this.page.total = data.total
        this.page.current = data.current 
        this.page.pageTemp = data.pageNum;
      })
    },
    // 每页显示数改变
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.page.size = val
      this.hangBookPageChange()
    },
    // 当前页改变
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.page.current = val
      this.hangBookPageChange()
    },

    //=================图书导出==================
    ExcelHandleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
    

    bookExcel(){
     

      let ids =[]
      if(this.excel.excelRadio == 10){
          this.multipleSelection.forEach(w => {
            console.log(w.book.id)
            ids.push(JSON.parse(w.book.id))
          });
        }else if(this.excel.excelRadio == 11){
          console.log(this.excel.startConditionSelect)
          ids = this.excel.startConditionSelect 
        }else{

        }
     
      exportBook(ids).then((res) => {
        
        //解析文件名
        // console.log(res.headers['content-disposition']+"========")
        const str = res.headers['content-disposition'].split(';')[1].split('=')[1];
        console.log(str)
        const filename = decodeURIComponent(str);
        
        // response.setHeader("Access-Control-Expose-Headers", "Content-disposition");
        //     response.setHeader("Content-disposition", "attachment;filename*=" + fileName + ".xls");
        // 用blob存储二进制文件
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8', // 设置文件类型 pdf是application/pdf，word是application/vnd.openxmlformats-officedocument.wordprocessingml.document，其他类型可以百度一下
        });
        // 创建一个临时的url指向blob对象
        const objectUrl = window.URL.createObjectURL(blob);
        // 创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        const a = document.createElement('a');
        a.setAttribute('href', objectUrl);
        

        a.setAttribute('download', filename);
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(objectUrl);
      })
      
    },
    bookExcel11() {
      let data =[]
      this.multipleSelection.forEach(w => {
        // multipleSelection.id
        console.log(w.id+"==========")
        
        data.push(JSON.parse(w.id))
      });
	  let xhr = new XMLHttpRequest();
				xhr.open("POST", 'https://api.leshop.cloud/book/export');
				xhr.responseType = 'blob';
				xhr.setRequestHeader('Content-Type','application/json');
        xhr.setRequestHeader('Authorization','eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyb2xlIjoiYWRtaW4iLCJiaWQiOjEsImFpZCI6MSwiYWNjb3VudCI6ImxldHUxMjM0IiwidXNlcm5hbWUiOiLnqIvmoLnno4ogICJ9.AkDHUs_6NXASjfwNqc1G9pB-eCO5ZFiSoSQK_5WBLeU');
        
        
				xhr.onload = function (){
					if(this.status==200){
						let blob = this.response;
						if(blob && blob.size > 0){
             
							let fileName = "图书数据表.xls";
							// 创建一个临时链接并模拟点击进行下载
							let a = document.createElement('a');
							a.href = window.URL.createObjectURL(blob);
							a.download = fileName;
							a.click();
						} else {
							console.error("下载失败");
						}
				  }
				}
	   xhr.send(JSON.stringify(data));
  },

  // =============图书导入=============
    bookExcelImport(){
      this.isImport=true
    }
  
  }
}
</script>

<style scoped>
.intro-item {
  display: block;
}
</style>

<style>
.intro-item .el-form-item__content {
  width: 85% !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.middle {
  display: flex;
  margin-top: 15px;
}

.middle .middle-item {
  margin-left: 10px;
}

.middle .circle {
  font-size: 20px;
  color: rgb(237, 64, 20);
  position: relative;
  margin-left: 10px;
}

.middle .circle-item {
  position: absolute;
  border: 1px solid rgb(237, 64, 20);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  top: 14px;
  right: -2.5px;
  cursor: pointer;
}

.bottomBak {
  display: flex;
  margin-top: 15px;
}

.bottomBak .txtBak {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #DCDFE6;
  color: #2d8cf0;
  cursor: pointer;
  line-height: 1;
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-right: 40px;
  margin-bottom: 6px;
}

.bottomBak .right {
  display: flex;
}
</style>